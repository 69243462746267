"use client";

import { useCookiebotConsent } from "@/hooks/cookiebot/use-cookiebot-consent.hook";
import { captureClientException } from "@/services/logging/capture-client-exception";
import type { ReactNode } from "react";
import { createContext, useContext, useEffect } from "react";
export type SnowplowModel = {
  readonly appId: string;
  readonly snowplowEndpoint: string;
  readonly site: string;
};
const SnowplowContext = createContext<SnowplowModel | undefined>(undefined);
type SnowplowProviderProps = {
  readonly model: SnowplowModel;
  readonly children: ReactNode;
};
type UseSnowplowContextReturn = {
  readonly trackingData: null | SnowplowModel;
};
export function useSnowplowContext(): UseSnowplowContextReturn {
  const trackingData = useContext(SnowplowContext) ?? null;
  return {
    trackingData
  };
}
export function SnowplowProvider({
  children,
  model
}: SnowplowProviderProps) {
  const consent = useCookiebotConsent();
  useEffect(() => {
    const initSnowplow = async () => {
      if (typeof window === "undefined") {
        return;
      }
      const {
        disableAnonymousTracking,
        newTracker
      } = await import(/* webpackChunkName: "snowplow-tracker" */"@snowplow/browser-tracker");
      newTracker("snowplowTracker", model.snowplowEndpoint, {
        anonymousTracking: {
          withServerAnonymisation: true
        },
        appId: model.appId,
        bufferSize: 1,
        contexts: {
          // Default
          session: false,
          webPage: true // Adds client session context entity to events, off by default. Available in v3.5+.
        },
        cookieDomain: model.site,
        cookieName: `_sp_${model.site}_1_`,
        cookieSameSite: "Lax",
        cookieSecure: true,
        discoverRootDomain: true,
        encodeBase64: false,
        eventMethod: "post",
        platform: "web",
        resetActivityTrackingOnPageView: true,
        respectDoNotTrack: true,
        stateStorageStrategy: "none"
      });
      if (consent.marketing) {
        disableAnonymousTracking({
          stateStorageStrategy: "cookieAndLocalStorage"
        });
      }
    };
    initSnowplow().catch(captureClientException);
  }, [model, consent]);
  return <SnowplowContext.Provider value={model} data-sentry-element="unknown" data-sentry-component="SnowplowProvider" data-sentry-source-file="snowplow.context.tsx">
      {children}
    </SnowplowContext.Provider>;
}